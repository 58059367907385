.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #f1f5fa;
}

.App-link {
  color: #61dafb;
}

.sortBox {
  display: flex;
  font-weight: bold;
  align-content: center;
  cursor: pointer;
}

.sortLabel {
  margin-right: 4px;
  padding-top: 1px;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.streamCell {
  display: flex;
  align-items: center;
}

.camCell {
  display: flex;
  align-items: center;
  color: #888282;
  font-weight: 500;
}

.arrow {
  top: calc((100% - 15px) / 2);
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #f1f5fa;
  /* filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32)); */
  right: -8px;
  transform: rotate(45deg)
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
